// import axios from "axios"
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "../../../../../libraries-configuration/axios-config/axios-config";
import apiCallMethods from "../../../../../libraries-configuration/api-methods/api-methods";
import {
  CREATEORDER,
  CURRENTPRICES,
  FEEDBACK_QUESTIONS,
  GET_ORDER_PRICING_DETAILS,
  GETITEMBYID,
  GETITEMLIST,
  GETORDERLOG,
  ORDERLIST,
  PAYMENTMETHODSLIST,
  RECURINGORDERCONFIRMEDLIST,
  SCHEDULEDORDERLIST,
} from "../../../reducer/unauth-reducer/order-reducer/order-reducer";

let endPoints = {
  getcustomerscheduledorder: "/RecurringOrders/GetScheduledOrders/",
  getcustomerorder: "/Order/GetCustomerOrders/",
  getcurrentpricesregionbased: "/OrderPricing/CurrentPricesRegionBased",
  getpaymentmethodslistall: "/PaymentMethods/ListAll",
  getitem: "/Item/Get/1",
  getitemlist: "/Item/ListAll",
  getorderschedulelogs: "/Order/GetOrderScheduleLogs/",
  cancelorder: "/Order/CancelOrder",
  createorder: "/Order/CreateOrder",
  createrecurringorder: "/RecurringOrders/CreateRecurringOrderV2",
  cancelrecurringorder: "/RecurringOrders/ControlRecurringOrderStatusActivity",
  returnsendrequest: "/Order/CreateEmptyPickupRequestOrder",
  feedbackQuestions: "/FeedBack/Questions",
  AddQAFeedBack: "/FeedBack/AddQAFeedBack",
  getOrderPricingDetails: "/Order/GetOrderPricingDetails",
};

const GetOrderPricingDetails = (Data, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url:
          endPoints.getOrderPricingDetails +
          `?CustomerId=${Data.authuserdetail.customer.id}` +
          `&CurrentPricingId=${Data.FilterObject.currentPriceId}` +
          `&Qty=${Data.quantity}` +
          `&InvoiceType=${Data.invoiceType}` +
          `&IsUrgent=${Data.isUrgent}`,
      });
      console.log("order pricing Details Data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: GET_ORDER_PRICING_DETAILS,
            payload: data?.data,
          });
          // resHandler(response);
          // toast.success(data?.message)
        } else {
          // resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      // resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetCustomerScheduledOrder = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getcustomerscheduledorder + customerId,
      });

      console.log("scheduled order List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: SCHEDULEDORDERLIST,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetCustomerOrder = (customerId, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getcustomerorder + customerId,
      });

      console.log("order List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: ORDERLIST,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetCurrentPricesRegionBased = (resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getcurrentpricesregionbased,
      });

      console.log("GetCurrentPricesRegionBased data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: CURRENTPRICES,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetPaymentMethodsListAll = (resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getpaymentmethodslistall,
      });

      console.log("GetPaymentMethodsListAll data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: PAYMENTMETHODSLIST,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetItemByID = (resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getitem,
      });

      console.log("GetItemByID data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: GETITEMBYID,
            payload: data?.data,
          });
          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetItemList = (resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getitemlist,
      });

      console.log("GetItem List data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: GETITEMLIST,
            payload: data?.data,
          });

          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const GetOrderScheduleLogs = (orderid, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints.getorderschedulelogs + orderid,
      });

      console.log("GetOrderScheduleLogs data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: GETORDERLOG,
            payload: data?.data,
          });

          resHandler(response);
          // toast.success(data?.message)
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const CancelOrder = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.cancelorder,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          // dispatch({
          //     type: CREATEORDER,
          //     payload: data?.data
          // })

          resHandler(response);
          toast.success(data?.message);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          resHandler(response);
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message); // Corrected toast method
    }
  };
};

const CreateOrder = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.createorder,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          dispatch({
            type: CREATEORDER,
            payload: data?.data,
          });

          resHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          resHandler(response);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

const CreateRecurringOrder = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.createrecurringorder,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200) {
          // dispatch({
          //     type: CREATEORDER,
          //     payload: data?.data
          // })

          resHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          resHandler(response);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

const CancelRecurringOrder = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.cancelrecurringorder,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200 || data?.succeeded === true) {
          // dispatch({
          //     type: CREATEORDER,
          //     payload: data?.data
          // })

          resHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          resHandler(response);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

const ReturnSendRequest = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.returnsendrequest,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200 || data?.succeeded === true) {
          // dispatch({
          //     type: CREATEORDER,
          //     payload: data?.data
          // })

          resHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          resHandler(response);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

const CancelSubscription = (formData, resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.cancelrecurringorder,
        data: formData,
      });

      console.log("Create Order data:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200 || data?.succeeded === true) {
          // dispatch({
          //     type: CREATEORDER,
          //     payload: data?.data
          // })

          resHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          resHandler(response);
        }
      }
    } catch (error) {
      resHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

const RecuringOrderConfirmed = (data) => {
  return (dispatch) => {
    const parsedData = Object.keys(data).map((key) => JSON.parse(data[key]));
    dispatch({
      type: RECURINGORDERCONFIRMEDLIST,
      payload: parsedData,
    });
  };
};

const fetchFeedbackQuestions = (resHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.GET,
        url: endPoints?.feedbackQuestions,
      });

      console.log("fetch feedback questions", response);
      let { data } = response;
      if (data?.statusCode == 200 && data?.succeeded == true) {
        dispatch({
          type: FEEDBACK_QUESTIONS,
          payload: data?.data,
        });

        resHandler(data);
      }
    } catch (error) {
      console.log("Feedback questions error:", error);
    }
  };
};

const updateFeedback = (formData, updatefeedbackresHandler) => {
  return async (dispatch) => {
    try {
      let response = await instance({
        method: apiCallMethods.POST,
        url: endPoints.AddQAFeedBack,
        data: formData,
      });

      console.log("Submit Feedback:", response);
      let { status, data } = response;

      if (status === 200) {
        if (data.statusCode === 200 || data?.succeeded === true) {
          updatefeedbackresHandler(response);
          toast.success(data?.message);
        } else if (data?.statusCode !== 200 || data?.succeeded === false) {
          toast.error(data?.message);
          updatefeedbackresHandler(response);
        }
      }
    } catch (error) {
      updatefeedbackresHandler(error?.response?.data);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  GetCustomerScheduledOrder,
  GetCustomerOrder,
  GetCurrentPricesRegionBased,
  GetPaymentMethodsListAll,
  GetItemByID,
  GetItemList,
  GetOrderScheduleLogs,
  CancelOrder,
  CreateOrder,
  CreateRecurringOrder,
  CancelRecurringOrder,
  ReturnSendRequest,
  CancelSubscription,
  RecuringOrderConfirmed,
  fetchFeedbackQuestions,
  updateFeedback,
  GetOrderPricingDetails,
};

<ToastContainer />; // Place the ToastContainer outside of functions
