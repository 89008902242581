import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import supportBannerRectangle from "../../../assets/support_rectangle.png";
import supportBannerLogo from "../../../assets/support_logoIcon.png";
import support from "../../../assets/support.png";
import supportLandline from "../../../assets/support_landline.png";
import supportMobile from "../../../assets/support_mobile.png";
import supportFaq from "../../../assets/support_faq.png";
import { Link } from "react-router-dom";
const CustomerSupport = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Stack bgcolor={"white"} padding={0}>
      <Box>
        <Box mb={5} position={"relative"} overflow={"hidden"} borderRadius={2}>
          <img
            src={supportBannerRectangle}
            alt=""
            height={200}
            width={"100%"}
          />
          <img
            src={supportBannerLogo}
            alt=""
            height={200}
            // width={"100%"}
            style={{
              position: "absolute",
              left: "50%",
              top: "0",
              transform: "translateX(-50%)",
            }}
          />
          <Typography
            variant="h4"
            color="white"
            fontWeight={700}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50% , -50%)",
            }}
          >
            Support
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={5}
          marginBottom={5}
          // border={2}
        >
          <Box>
            <img src={support} alt="" />
          </Box>
          <Box>
            <Typography
              variant={isSmallScreen ? "h6" : "h4"}
              color="#1591C7"
              fontWeight={700}
              textAlign={"center"}
            >
              Hello, How we can <br /> Help you?
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
        gap={5}
        paddingY={5}
        bgcolor={"#E9E9E9"}
        borderRadius={2}
      >
        <Box>
          <Stack spacing={3} width={isSmallScreen ? "250px" : "400px"}>
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              Contact us
            </Typography>

            <Box>
              <Typography variant="subtitle1">Landline</Typography>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  gap: "10px",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
              >
                {/* <PhoneIcon color="primary" sx={{ marginRight: "8px" }} /> */}
                <img src={supportLandline} alt="" width={20} />
                <Typography color="#1591C7">
                  <a href="tel:+925813550295" style={{ color: "#1591C7" }}>
                    +92 58135 50295
                  </a>
                </Typography>
              </Paper>
            </Box>

            <Box>
              <Typography variant="subtitle1">Mobile</Typography>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  gap: "10px",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
              >
                {/* <PhoneIcon color="primary" sx={{ marginRight: "8px" }} /> */}
                <img src={supportMobile} alt="" width={15} />
                <Typography color="#1591C7">
                  <a href="tel:+923555699977" style={{ color: "#1591C7" }}>
                    0355-5699977
                  </a>
                </Typography>
              </Paper>
            </Box>

            <Box>
              <Typography variant="subtitle1">FAQs</Typography>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  gap: "10px",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
              >
                {/* <PhoneIcon color="primary" sx={{ marginRight: "8px" }} /> */}
                <img src={supportFaq} alt="" width={20} />
                <Typography color="#1591C7">
                  <Link to={"/FAQ"} style={{ color: "#1591C7" }}>
                    What is blue?
                  </Link>
                </Typography>
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default CustomerSupport;
