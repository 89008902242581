import React, { useEffect, useState } from 'react'
import './Sidenav_bar.css';
import "boxicons/css/boxicons.min.css";
import 'font-awesome/css/font-awesome.min.css';
import { FaRegCircleDot } from "react-icons/fa6";
import { LuLayoutDashboard } from "react-icons/lu";
import { Link } from 'react-router-dom';
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaCircleArrowLeft } from "react-icons/fa6";
import white_logo from "../../assets/logo-blue1.png";
import { AiOutlineHome } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { GrMapLocation } from "react-icons/gr";
import { LiaCreditCardSolid } from "react-icons/lia";
import { SlWallet } from "react-icons/sl";
import { useDispatch, useSelector } from 'react-redux';
import { LogoutUser } from '../../redux/store/action/auth-action/auth-action';
import { MdLogout } from "react-icons/md";
import { SlSettings } from "react-icons/sl";
import Account_Deletion from '../../Modal/Account-Deletion';
import user__img from "../../../src/assets/user_img.png";
import { FaQuestionCircle } from "react-icons/fa";
import { LuFileType } from "react-icons/lu";
import { AiOutlineFilePpt } from "react-icons/ai";
import { Help, SupportAgent } from '@mui/icons-material';
import { IS_URGENT_VIEW_CLEAR, SET_HIDE_ADDRESS_MODAL_CLEAR } from '../../redux/store/reducer/auth-reducer/auth-reducer';





const Sidenav_bar = () => {

    const dispatch = useDispatch();


    const { authuserdetail } = useSelector(({ authStates }) => authStates);

    const [imageSrc, setImageSrc] = useState(authuserdetail?.base64Image || user__img);



    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [togglebutton, settogglebutton] = useState(true);
    const [open, setopen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const toggleSubMenu = (e) => {
        const listItem = e.target.closest('li');
        const isSubMenuOpen = listItem.classList.contains("showMenu");

        // Close all other open dropdowns
        const allListItems = document.querySelectorAll('.nav-links > li');
        allListItems.forEach(item => {
            if (item !== listItem) {
                item.classList.remove("showMenu");
            }
        });

        // Open or close the clicked dropdown
        if (!isSubMenuOpen) {
            listItem.classList.add("showMenu");
        } else {
            listItem.classList.remove("showMenu");
        }
    };

    useEffect(() => {
        // Close sidebar by default on smaller screens
        const mediaQuery = window.matchMedia('(max-width: 900px)');
        if (mediaQuery.matches) {
            setIsSidebarOpen(false);
            settogglebutton(false);
        }
    }, []);



    const logout = () => {
        dispatch(LogoutUser())
        dispatch(IS_URGENT_VIEW_CLEAR(false));
        dispatch(SET_HIDE_ADDRESS_MODAL_CLEAR(false));
    }

    const handleOpen = () => {
        setopen(true)
    }

    const handleClose = () => {
        setopen(false)
    }

    return (
        <>
            <Account_Deletion
                open={open}
                handleClose={handleClose}
                title="Modal title"
            >
                This is the modal content.
            </Account_Deletion>

            <div className={`sidebar ${isSidebarOpen ? "" : "close"}`}>
                <div className="logo-details">
                    <Link to='/'>
                        <span>
                            <img src={white_logo} alt='' />
                        </span>
                    </Link>
                </div>
                {togglebutton ?
                    (
                        <div className='toggleSidebar'>
                            <i className='bx bx-menu' onClick={toggleSidebar} style={{ cursor: 'pointer' }}></i>
                        </div>
                    ) : (
                        <div className='toggleSidebarclose'>
                            {isSidebarOpen ? (
                                <FaCircleArrowLeft onClick={toggleSidebar} />
                            ) : (
                                <FaCircleArrowRight onClick={toggleSidebar} />
                            )}
                        </div>
                    )
                }


                <ul className="nav-links">
                    <li>
                        <Link to="/">
                            <i>
                                <AiOutlineHome />
                            </i>
                            <span className="link_name">Home</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/">Home</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/my-profile">
                            <i>
                                <FaRegUser />
                            </i>
                            <span className="link_name">My Profile</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/my-profile">My Profile</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/order-list">
                            <i>
                                <TbReportSearch />
                            </i>
                            <span className="link_name">Order list</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/order-list">Order list</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/manage-address">
                            <i>
                                <GrMapLocation />
                            </i>
                            <span className="link_name">Manage Address</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/manage-address">Manage Address</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/Manage-Cards">
                            <i>
                                <LiaCreditCardSolid />
                            </i>
                            <span className="link_name">Manage Cards</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/Manage-Cards">Manage Cards</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/wallet">
                            <i>
                                <SlWallet />
                            </i>
                            <span className="link_name">Wallet</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/wallet">Wallet</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/support">
                            <i>
                                <SupportAgent />
                            </i>
                            <span className="link_name">Support</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/wallet">Wallet</Link></li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/FAQ">
                            <i>
                                <FaQuestionCircle />
                            </i>
                            <span className="link_name">FAQ</span>
                        </Link>
                        {/* <ul className="sub-menu blank">
                            <li><Link className="link_name" to="/wallet">Wallet</Link></li>
                        </ul> */}
                    </li>

                    <li>
                        <div className="iocn-link" onClick={toggleSubMenu}>
                            <Link to="#">
                                <i>
                                    <SlSettings />
                                </i>
                                <span className="link_name">Setting</span>
                            </Link>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Setting</Link></li>
                            <li><Link to="#" onClick={handleOpen}><FaRegCircleDot />Account Deletion</Link></li>
                        </ul>
                    </li>

                    <li>
                        <Link to="/Terms-and-Condition">
                            <i>
                                <LuFileType />
                            </i>
                            <span className="link_name">Terms and Condition</span>
                        </Link>
                    </li>

                    <li>
                        <Link to="/Privacy-Policy">
                            <i>
                                <AiOutlineFilePpt />
                            </i>
                            <span className="link_name">Privacy Policy</span>
                        </Link>
                    </li>

                    {/* <li>
                        <div className="iocn-link" onClick={toggleSubMenu}>
                            <Link to="#">
                                <i>
                                    <PiUsersThree />
                                </i>
                                <span className="link_name">Administrator</span>
                            </Link>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><Link className="link_name" to="#">Administrator</Link></li>
                            <li><Link to="/Register-User"><FaRegCircleDot /> Register User</Link></li>
                            <li><Link to="/User-Access"><FaRegCircleDot /> User Access</Link></li>
                            <li><Link to="#"><FaRegCircleDot /> Change Password</Link></li>
                            <li><Link to="#"><FaRegCircleDot /> Create User Type</Link></li>
                        </ul>
                    </li> */}

                    {/* Logout Section Start Here */}
                    <li>
                        <div className="profile-details">
                            <div className="profile-content">
                                <img src={authuserdetail?.base64Image && authuserdetail?.base64Image !== "NA" ? authuserdetail?.base64Image : user__img} alt="profileImg" />
                            </div>
                            <div className="name-job">
                                <div className="profile_name">Logout</div>
                                {/* <div className="job">Software Engineer</div> */}
                            </div>
                            <i onClick={logout}>
                                <MdLogout />
                            </i>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Sidenav_bar