import React, { useState, useEffect, useMemo } from "react";
import "./Dashboard.css";
import user_img from "../../../assets/user_img.png";
import cylinder_img from "../../../assets/Group 11613@3x.png";
import banner_img from "../../../assets/banner-img.png";
import mob_banner_img from "../../../assets/mobile-banner-img.png";
import yourorder from "../../../assets/your-order.png";
import Inhousecylinder from "../../../assets/cylinder.png";
import UnsubscribeCylinders from "../../../assets/return-cylinder.png";
import Nextorders from "../../../assets/calendar.png";
import { SlWallet } from "react-icons/sl";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Your_Order from "./Your-Order/Your-Order";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCustomerDetails,
  setHideAddressModal,
  setHideAddressModalStop,
  setPrevOrderList,
} from "../../../redux/store/action/auth-action/auth-action";
import Next_orders from "./Next-orders/Next-orders";
import In_house_cylinder from "./In-house-cylinder/In-house-cylinder";
import Unsubscribe_Cylinders from "./Unsubscribe-Cylinders/Unsubscribe-Cylinders";
import { GetCustomerWallet } from "../../../redux/store/action/unauth-action/wallet-action/wallet-action";
import {
  GetCurrentPricesRegionBased,
  GetCustomerOrder,
  GetCustomerScheduledOrder,
  GetItemByID,
  GetItemList,
  GetPaymentMethodsListAll,
  fetchFeedbackQuestions,
  updateFeedback,
} from "../../../redux/store/action/unauth-action/order-action/order-action";
import { ImCross } from "react-icons/im";
import { GetListAllCylinderStatus } from "../../../redux/store/action/unauth-action/dashboard-action/dashboard-action";
import user__img from "../../../../src/assets/user_img.png";
import { toast } from "react-toastify";
import {
  Button,
  IconButton,
  Modal,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../Loader/Loader";
import {
  AddAddress,
  SelectAddress,
} from "../../../redux/store/action/unauth-action/address-action/address-action";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //   setHideAddressModal
  const {
    userId,
    addressList,
    authuserdetail,
    hideAddressModal,
    hideAddressModalStop,
    prevOrderList,
  } = useSelector(({ authStates }) => authStates);
  const { walletList } = useSelector(({ walletStates }) => walletStates);
  const { currentprices, orderList, scheduledorderList, feedbackQuestions } =
    useSelector(({ orderStates }) => orderStates);
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { AllCylinderStatusList } = useSelector(
    ({ dashboardStates }) => dashboardStates
  );
  const phoneNumber = localStorage.getItem("phoneNumber");
  
  // console.log('authuserdetail', authuserdetail)
  
  const [imageSrc, setImageSrc] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [togglebannerimage, settogglebannerimage] = useState(false);
  
  const resHandler = (data) => {
    if (data) {
    }
  };
  // Filter orders with status 'Delivered'
  const deliveredOrders = orderList?.orders.filter(
    (order) => order.status === "Delivered"
  );
  console.log("orderList ", deliveredOrders, prevOrderList);

  useEffect(() => {
    dispatch(GetCustomerDetails(userId));
    dispatch(GetCurrentPricesRegionBased(resHandler));
    dispatch(GetPaymentMethodsListAll(resHandler));
    dispatch(GetItemByID(resHandler));
    dispatch(GetItemList(resHandler));
    dispatch(fetchFeedbackQuestions(resHandler));
    // dispatch(setPrevOrderList([]));
  }, []);

  useEffect(() => {
    if (authuserdetail?.customer?.customerId) {
      dispatch(
        GetListAllCylinderStatus(
          authuserdetail?.customer?.customerId,
          resHandler
        )
      );
      dispatch(
        GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler)
      );
      dispatch(
        GetCustomerScheduledOrder(
          authuserdetail?.customer?.customerId,
          resHandler
        )
      );
      dispatch(
        GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
      );
    }
  }, [authuserdetail?.customer?.customerId]);

  useEffect(() => {
    console.log("SELECTED ADDRESS:", selectaddress);
    console.log("CURRENT PRICES", currentprices);
    if (selectaddress !== null) {
      if (!selectaddress || !currentprices || currentprices.length === 0) {
        setIsOpen(false);
      } else {
        const foundCity = currentprices?.some(
          (price) => price?.city === selectaddress?.city.trim()
        );
        setIsOpen(!foundCity);
      }
    }
  }, [selectaddress, currentprices]);

  const YourOrderFilterData = orderList?.orders?.filter(
    (row) => row?.status !== "Delivered"
  );
  const inactivetrueOrders = scheduledorderList?.filter(
    (order) => order?.isScheduleActive === true
  );
  const unsubscribeCylinderList = scheduledorderList?.filter(
    (item) => item?.isScheduleActive == false
  );
  const totalUnsubscribedCylinders = unsubscribeCylinderList
    ?.filter((item) => item?.totalCylindersInHand != 0)
    .map((item) => item.totalCylindersInHand);

  useEffect(() => {
    // Close sidebar by default on smaller screens
    const mediaQuery = window.matchMedia("(max-width: 900px)");
    if (mediaQuery.matches) {
      settogglebannerimage(true);
    }
  }, []);

  const your_ordes_data = useMemo(() => {
    return orderList && orderList.orders
      ? orderList?.orders.filter(
          (row) =>
            row?.status !== "Delivered" &&
            row?.status !== "Deactivated" &&
            row?.status !== "RequestCompleted"
        )
      : [];
  }, [orderList]);
  console.log("your ordes data ", your_ordes_data);

  const [isLoading, setIsloading] = useState(false);
  const [Open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [orderid, setOrderid] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [comments, setComments] = useState(null);
  const [openModalcheckaddress, setopenModalcheckaddress] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [rowData, setRowData] = useState(null);
  const [type, setType] = useState(null);
  const [lastOrderAddress, setLastOrderAddress] = useState("");
  const [lastOrderCity, setLastOrderCity] = useState("");
  const [lastOrderProvince, setLastOrderProvince] = useState("");
  const [lastOrderLang, setLastOrderLang] = useState(null);
  const [lastOrderLat, setLastOrderLat] = useState(null);

  function openModal(data) {
    setOrderid(data?.id);
    console.log("data");
    console.log(data);
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
    setOrderid(null);
    setValue(0);
    setAnswers([]);
    setSelectedValue([]);
    setComments(null);
  }

  const handleAnswerChange = (questionId, userAnswer) => {
    const existingAnswerIndex = answers.findIndex(
      (ans) => ans.questionId === questionId
    );
    const updatedAnswers = [...answers];
    const updatedSelectedValue = [...selectedValue];

    if (existingAnswerIndex !== -1) {
      updatedAnswers[existingAnswerIndex].userAnswer = userAnswer;
      updatedSelectedValue[existingAnswerIndex].userAnswer =
        userAnswer === "Yes" ? "Yes" : "No";
    } else {
      updatedAnswers.push({ questionId, userAnswer });
      updatedSelectedValue.push({
        questionId,
        userAnswer: userAnswer === "Yes" ? "Yes" : "No",
      });
    }

    console.log("updatedAnswers", updatedAnswers);
    console.log("updatedSelectedValue", updatedSelectedValue);

    setAnswers(updatedAnswers);
    setSelectedValue(updatedSelectedValue);
  };

  const updatefeedbackresHandler = (data) => {
    if (data) {
      dispatch(
        GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler)
      );
      setIsloading(false);
      closeModal();
    }
  };

  const submitfeedback = () => {
    if (value === 0) {
      toast.error("Please enter a Rateing");
    } else if (
      answers.length < 1 ||
      answers.length < feedbackQuestions.length
    ) {
      toast.error(
        "Please answer all the questions before submitting feedback."
      );
    } else if (!comments) {
      toast.error("Please enter a Comment");
    } else {
      setIsloading(true);

      let formData = {
        review: "string",
        rate: Number(value),
        detailComment: comments,
        orderId: orderid,
        questionAnswers: answers,
      };

      dispatch(updateFeedback(formData, updatefeedbackresHandler));
    }
  };

  useEffect(() => {
    if (orderList) {
      checkfeedback();
    }
  }, [orderList]);

  const checkfeedback = () => {
    const data = orderList?.orders
      ? orderList.orders
          .filter((row) => row.status === "Delivered")
          .sort((a, b) => a.id - b.id)
      : [];

    // Reverse the order of elements in the array
    const feedbackarrayReverse = data?.reverse(); // Create a copy of the array before reversing

    if (
      feedbackarrayReverse?.length > 0 &&
      feedbackarrayReverse[0]?.isFeedGiven === false
    ) {
      openModal(feedbackarrayReverse[0]);
    }
  };

  const handleopenModalcheckaddress = (row) => {
    setRowData(row.original);
    setopenModalcheckaddress(true);
  };

  const handleCloseModal = () => {
    dispatch(setHideAddressModal(false));
    // setopenModalcheckaddress(false);
  };

  const completedOrders = orderList?.orders
    ?.filter((row) => row?.status === "Delivered")
    .sort((a, b) => a.id - b.id);
  const reversedCompletedOrders = completedOrders?.slice().reverse();

  const fetchAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8`
      );
      const data = await response.json();
      if (data?.status === "OK") {
        const address = data?.results[0].formatted_address;
        const addressComponents = data?.results[0].address_components;
        const city =
          addressComponents?.find((component) =>
            component?.types.includes("locality")
          )?.long_name || "";
        const province =
          addressComponents?.find((component) =>
            component?.types.includes("administrative_area_level_1")
          )?.long_name || "";

        setLastOrderAddress(address);
        setLastOrderCity(city);
        setLastOrderProvince(province);

        return address;
      } else {
        console.error("Error fetching address:", data?.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      return null;
    }
  };

  const calculateDistanceAndopenModalcheckaddress = () => {
    const lastOrder = reversedCompletedOrders?.[0];
    if (!lastOrder) return;

    const lastOrderLang = lastOrder?.currentScheduleStatus?.orderAtLang;
    const lastOrderLat = lastOrder?.currentScheduleStatus?.orderAtLat;
    const currentAddressLang =
      lastOrder?.currentScheduleStatus?.selectedLongWhenPlacedOrder;
    const currentAddressLat =
      lastOrder?.currentScheduleStatus?.selectedLatWhenPlacedOrder;

    if (lastOrderLat && lastOrderLang) {
      setLastOrderLang(lastOrderLang);
      setLastOrderLat(lastOrderLat);
      fetchAddressFromCoordinates(lastOrderLat, lastOrderLang);
    }

    if (
      lastOrderLang &&
      lastOrderLat &&
      currentAddressLang &&
      currentAddressLat
    ) {
      const haversineDistance = (coords1, coords2) => {
        const toRad = (x) => (x * Math.PI) / 180;
        const R = 6371e3; // Earth's radius in meters

        const dLat = toRad(coords2.lat - coords1.lat);
        const dLon = toRad(coords2.lon - coords1.lon);
        const lat1 = toRad(coords1.lat);
        const lat2 = toRad(coords2.lat);

        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(lat1) *
            Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
      };

      const distance = haversineDistance(
        { lat: lastOrderLat, lon: lastOrderLang },
        { lat: currentAddressLat, lon: currentAddressLang }
      );

      console.log("Distance data: ", distance);

      const isLastOrderAddressInList = addressList?.some(
        (address) =>
          address?.longitude === lastOrderLang &&
          address?.latitude === lastOrderLat
      );

      if (distance > 25 && !isLastOrderAddressInList && !hideAddressModalStop) {
        dispatch(setHideAddressModal(true));
        dispatch(setHideAddressModalStop(true));
        dispatch(setPrevOrderList(deliveredOrders));
        // setopenModalcheckaddress(true); // Set state to open modal
      }

      if (deliveredOrders?.length > prevOrderList?.length) {
        dispatch(setHideAddressModal(false));
        dispatch(setHideAddressModalStop(false));
        dispatch(setPrevOrderList(deliveredOrders));
      }
    }
  };

  useEffect(() => {
    calculateDistanceAndopenModalcheckaddress();
  }, [addressList]);

  const resHandlerModal = (data) => {
    if (data) {
      let formData = {
        phoneNumber: phoneNumber,
        userGUID: userId,
        address: lastOrderAddress,
        street: "",
        houseNo: "",
        city: lastOrderCity,
        type: type,
        province: lastOrderProvince,
        longitude: String(lastOrderLang),
        latitude: String(lastOrderLat),
      };
      dispatch(SelectAddress(formData));

      setopenModalcheckaddress(false);
      navigate("/manage-address");
    }
  };

  const handleYes = () => {
    // if (!type) {
    //   toast.error("Please enter a Address Type!");
    // } else if (!type.trim()) {
    //   toast.error("Please enter a Address Type!");
    // } else {
    //   let formData = {
    //     phoneNumber: phoneNumber,
    //     userGUID: userId,
    //     address: lastOrderAddress,
    //     street: "",
    //     houseNo: "",
    //     city: lastOrderCity,
    //     type: type,
    //     province: lastOrderProvince,
    //     longitude: String(lastOrderLang),
    //     latitude: String(lastOrderLat),
    //   };

    //   dispatch(AddAddress(formData, resHandlerModal));
    // }
    navigate("/manage-address", { state: { addNewAddress: true } });
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      {/* Feedback Modal Start Here */}
      <Modal
        open={Open}
        // onRequestClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_account_deletion_div">
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginTop: "40px",
              marginBottom: "30px",
            }}
          >
            Feedback
            <p style={{ fontSize: "1rem", color: "gray" }}>
              Order ID {orderid}
            </p>
          </Typography>
          {/* <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: ".rem",
            }}
          >
            <Rating
              name="no-value"
              size="large"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <p style={{ fontSize: "1.2rem", color: "gray" }}>
              Please Provide Your Feedback
            </p>
          </div>

          <div>
            {feedbackQuestions && feedbackQuestions?.length > 0 ? (
              feedbackQuestions?.map((item, index) => {
                return (
                  <div key={index} className="main_question_holder_div">
                    <div className="inner_single_question_holder_div">
                      <p style={{ color: "#0A74B9", fontSize: "1.2rem" }}>
                        {item?.question}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "1.5rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          <input
                            id={`yes${item?.id}`}
                            type="radio"
                            name={item?.id}
                            value="Yes"
                            checked={
                              selectedValue?.find(
                                (ans) => ans?.questionId === item?.id
                              )?.userAnswer === "Yes"
                            }
                            onChange={() => handleAnswerChange(item?.id, "Yes")}
                          />
                          <label
                            style={{ margin: "0" }}
                            htmlFor={`yes${item?.id}`}
                          >
                            Yes
                          </label>
                        </div>
                        <div
                          htmlFor=""
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          <input
                            id={`no${item.id}`}
                            type="radio"
                            name={item.id}
                            value="No"
                            checked={
                              selectedValue?.find(
                                (ans) => ans?.questionId === item?.id
                              )?.userAnswer === "No"
                            }
                            onChange={() => handleAnswerChange(item.id, "No")}
                          />
                          <label
                            style={{ margin: "0" }}
                            htmlFor={`no${item.id}`}
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No Questions Found</p>
            )}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "90%" }}
              id="outlined-multiline-static"
              label="Any Other Comments"
              multiline
              minRows={4}
              maxRows={8}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>

          <Stack direction="row" justifyContent="center" spacing={7} p={4}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
                "&:hover": {
                  backgroundColor: "#195389",
                },
              }}
              onClick={submitfeedback}
            >
              Submit
            </Button>
          </Stack>
        </div>
      </Modal>
      {/* Feedback Modal End Here */}

      {/* Check You Current Delivery Address And Last Delivery Address Modal Start Here */}
      <Modal
        // open={openModalcheckaddress}
        open={hideAddressModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_modal_div">
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "10px",
            }}
          ></Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px",
                marginTop: "58px",
              }}
            >
              {/* Your current delivery address is */}
              Your last delivery address is not in your address list and your
              current address is
            </Typography>
            <Typography
              sx={{
                color: "#1591c7",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              {/* {selectaddress?.address?.length > 30
                                ? `${selectaddress?.address?.substring(0, 30)}...`
                                : selectaddress?.address} */}
              {selectaddress?.address}
            </Typography>
            {/* <Typography
              sx={{
                color: "#1591c7",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              Your last delivery address was
            </Typography>
            <Typography
              sx={{
                width: "80%",
                color: "#1591c7",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              {lastOrderAddress}
            </Typography> */}
            {/* <TextField
                            label="Address Type"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "60%" }}
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        /> */}
            <Typography
              sx={{
                width: "50%",
                color: "#000",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              Do you want to continue your delivery on your last delivery
              address?
            </Typography>
          </div>
          <Stack direction="row" justifyContent="center" spacing={3} p={0}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={() => handleYes()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={handleCloseModal}
            >
              No
            </Button>
          </Stack>
        </div>
      </Modal>
      {/* Check You Current Delivery Address And Last Delivery Address Modal End Here */}

      <div className="main_dashboard_div">
        <div className="inner_dashboard_header_div">
          <div className="main_dashboard_header_user_profile_div">
            <div className="inner_dashboard_header_user_img_div">
              <img
                src={
                  authuserdetail?.base64Image &&
                  authuserdetail?.base64Image !== "NA"
                    ? authuserdetail?.base64Image
                    : user__img
                }
                alt=""
              />
            </div>
            <div className="inner_dashboard_header_user_details_div">
              <div className="inner_dashboard_header_user_details_div1">
                Hello!
              </div>
              <div className="inner_dashboard_header_user_details_div2">
                {authuserdetail?.customer?.name}
              </div>
              <div className="inner_dashboard_header_user_details_div3">
                <SlWallet /> Rs.{" "}
                {walletList?.wallet?.balance
                  .toFixed(0)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
          </div>

          <div className="dashboard_header_order_now_div">
            <Link to={modalIsOpen ? "#" : "/order-now"}>
              <div>Order Now</div>
              <img src={cylinder_img} alt="" />
            </Link>
          </div>
        </div>

        <div className="inner_dashboard_slider_div">
          <Slider {...settings}>
            <div className="inner_banner_image_div">
              <img
                src={togglebannerimage ? mob_banner_img : banner_img}
                alt=""
              />
            </div>
          </Slider>
        </div>

        <div className="inner_dashboard_cards_tabs_div">
          {modalIsOpen ? (
            <div className="main_dashboard_modal_div">
              <div className="inner_dashboard_modal_icon_div">
                <ImCross />
              </div>
              <div className="inner_dashboard_modal_text_div">
                Oops! Currently Our Servies <br /> are not working in Your Area
              </div>
            </div>
          ) : (
            <Tabs>
              <TabList>
                <Tab>
                  <div className="main_dashboard_card_div">
                    <div className="main_dashboard_card_inner_div1">
                      <div className="main_dashboard_card_inner_div1_heading">
                        Your Order
                      </div>
                      <div className="main_dashboard_card_inner_div1_text">
                        {your_ordes_data?.length}
                      </div>
                    </div>
                    <div className="main_dashboard_card_inner_div2">
                      <img src={yourorder} alt="" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="main_dashboard_card_div">
                    <div className="main_dashboard_card_inner_div1">
                      <div className="main_dashboard_card_inner_div1_heading">
                        In house cylinder
                      </div>
                      <div className="main_dashboard_card_inner_div1_text">
                        {AllCylinderStatusList?.length}
                      </div>
                    </div>
                    <div className="main_dashboard_card_inner_div2">
                      <img src={Inhousecylinder} alt="" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="main_dashboard_card_div">
                    <div className="main_dashboard_card_inner_div1">
                      <div className="main_dashboard_card_inner_div1_heading">
                        Unsubscribe Cylinders
                      </div>
                      <div className="main_dashboard_card_inner_div1_text">
                        {totalUnsubscribedCylinders?.length < 0
                          ? 0
                          : totalUnsubscribedCylinders?.reduce(
                              (a, b) => a + b,
                              0
                            )}
                      </div>
                    </div>
                    <div className="main_dashboard_card_inner_div2">
                      <img
                        src={UnsubscribeCylinders}
                        alt=""
                        style={{ width: "45%" }}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="main_dashboard_card_div">
                    <div className="main_dashboard_card_inner_div1">
                      <div className="main_dashboard_card_inner_div1_heading">
                        Next orders
                      </div>
                      <div className="main_dashboard_card_inner_div1_text">
                        {inactivetrueOrders?.length}
                      </div>
                    </div>
                    <div className="main_dashboard_card_inner_div2">
                      <img src={Nextorders} alt="" />
                    </div>
                  </div>
                </Tab>
              </TabList>

              <TabPanel>
                <Your_Order />
              </TabPanel>
              <TabPanel>
                <In_house_cylinder />
              </TabPanel>
              <TabPanel>
                <Unsubscribe_Cylinders />
              </TabPanel>
              <TabPanel>
                <Next_orders />
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
