/*Note: Auth Reducer ***/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scheduledorderList: null,
  orderList: null,
  currentprices: null,
  paymentmethodslist: null,
  itemlistbyid: null,
  itemlist: null,
  getorderlog: null,
  createorderdetail: null,
  recuringorderconfirmedlist: null,
  feedbackQuestions: [],
  orderPricingDetails: {},
};

const ordrSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    SCHEDULEDORDERLIST: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.scheduledorderList = payload;
    },
    ORDERLIST: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.orderList = payload;
    },
    CURRENTPRICES: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.currentprices = payload;
    },
    PAYMENTMETHODSLIST: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.paymentmethodslist = payload;
    },
    GETITEMBYID: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.itemlistbyid = payload;
    },
    GETITEMLIST: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.itemlist = payload;
    },
    GETORDERLOG: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.getorderlog = payload;
    },
    CREATEORDER: (state, action) => {
      const { payload } = action;
      console.log("payload:", payload);
      state.createorderdetail = payload;
    },
    RECURINGORDERCONFIRMEDLIST: (state, action) => {
      const { payload } = action;
      console.log("RECURINGORDERCONFIRMEDLIST payload:", payload);
      state.recuringorderconfirmedlist = payload;
    },
    FEEDBACK_QUESTIONS: (state, action) => {
      let { payload } = action;
      state.feedbackQuestions = payload;
    },
    GET_ORDER_PRICING_DETAILS: (state, action) => {
      let { payload } = action;
      state.orderPricingDetails = payload;
    },
  },
});

export const {
  SCHEDULEDORDERLIST,
  ORDERLIST,
  CURRENTPRICES,
  PAYMENTMETHODSLIST,
  GETITEMBYID,
  GETITEMLIST,
  GETORDERLOG,
  CREATEORDER,
  RECURINGORDERCONFIRMEDLIST,
  FEEDBACK_QUESTIONS,
  GET_ORDER_PRICING_DETAILS,
} = ordrSlice.actions;

export default ordrSlice.reducer;
